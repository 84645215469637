@use "./variables" as *;

.tabsWrap {
  background: $white;
  padding: 1rem 1.25rem;
  border-bottom: 1px solid $borderLight;
  @media screen and (max-width: 639px) {
    padding: 1rem;
  }
  .tabs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    &__link {
      position: relative;
      font-size: 0.75rem;
      font-weight: 400;
      color: $darkgray;
      line-height: 12px;
      text-transform: capitalize;
      cursor: pointer;
      &::after {
        content: "";
        background: $primary;
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        right: 0;
        bottom: -18px;
        opacity: 0;
        z-index: 1;
      }
      &--selected {
        font-weight: 600;
        color: $primary;
        text-decoration: none;
        &::after {
          opacity: 1;
        }
      }
    }
  }
}
.tabsContent {
  &__content {
    display: none;
    &--selected {
      display: block;
    }
  }
}
.filter {
  position: relative;
  &__dropdown {
    display: none;
    position: absolute;
    width: 400px;
    top: 100%;
    left: -1.875rem;
    right: 0;
    box-shadow: 0px 0px 56.21px 0px #0000001f;
    z-index: 1;
    margin-top: 22px;
    &::after {
      content: "";
      position: absolute;
      top: -22px;
      left: 60px;
      width: 0;
      height: 0;
      border-style: solid;
      border-right: 11px solid transparent;
      border-left: 11px solid transparent;
      border-bottom: 22px solid $white;
      border-top: 0;
      box-shadow: 0px 0px 56.21px 0px #0000001f;
      z-index: 1;
    }
    &__detail {
      background: $white;
      border-radius: 12px;
    }
    &__body {
      display: flex;
      flex-direction: column;
      background: $white;
      padding: 1.5rem 1.875rem;
      gap: 16px;
      .form__label {
        margin-bottom: 0.5rem;
      }
    }
    &__footer {
      background: rgba($lightgray, 30%);
      padding: 1.5rem 1.875rem;
    }
  }
  &--open {
    .filter__dropdown {
      display: block;
      max-height: calc(100dvh - 235px);
      // height: calc(100dvh - 235px);
      overflow-y: auto;
    }
  }
}
.uppercase {
  text-transform: uppercase;
}
.successMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 12px;
  min-height: 250px;
  &__icon {
    margin-bottom: 1.5rem;
  }
  .title {
    font-size: 1.5rem;
    font-weight: 600;
    color: $primaryBlack;
  }
  p {
    font-size: 1.25rem;
    font-weight: 400;
    color: $secondary;
    margin-bottom: 0;
  }
}
.messageSuccess {
  &__text {
    background: rgba($success, 15%);
    padding: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
    color: $success;
    border-radius: 6px;
  }
}
.messageError {
  &__text {
    background: rgba($danger, 15%);
    padding: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
    color: $danger;
    border-radius: 6px;
  }
}

.multiSelectChip {
  font-size: 0.875rem;
  font-weight: 300;
  width: 100%;
  color: $black;
  min-height: auto;
  padding: 0.5rem 2.5rem 0.5rem 1rem;
  border: 1px solid rgba($border, 35%);
  border-radius: 0.375rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  background: $white url("../../svg/down-arrow.svg") right 1rem top 1rem
    no-repeat;
  .chips {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    .chip__item {
      background-color: $primary;
      color: $white;
      padding: 4px 10px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      gap: 8px;
      text-transform: capitalize;
      .chip__close {
        width: 15px;
        height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $white;
        border: none;
        border-radius: 50%;
        color: $primaryBlack;
        cursor: pointer;
      }
    }
  }
  input {
    width: 100%;
    border: none;
    outline: none;
    outline: none;
    background: transparent;
    &:focus {
      border-color: none;
    }
  }
  .dropdown {
    background: $white;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    box-shadow: -1px -2px 9.8px 0px #0000001a;
    box-shadow: 0px 1px 2.2px 0px #00000040;
    z-index: 1;
    height: auto;
    max-height: 243px;
    overflow: hidden;
    overflow-y: auto;
    padding: 8px;
    .dropdown__item {
      display: flex;
      gap: 10px;
      align-items: center;
      padding: 8px;
      cursor: pointer;
      text-transform: capitalize;
      border-radius: 5px;
      input {
        width: auto;
      }
      &:hover {
        background-color: #f1f1f1;
      }
    }
    .dropdown__noitem {
      padding: 8px;
      text-align: center;
      color: #999;
    }
    .loading {
      padding: 8px;
      text-align: center;
    }
  }
}
.multiSelectChip__disabled {
  background-color: #d2d6db80;
  border: 1px solid #d2d6db80;
  cursor: not-allowed;
  .chips {
    .chip__item {
      .chip__close {
        cursor: not-allowed;
      }
    }
  }
}

.outline {
  display: inline-flex;
  align-items: center;
  border: 2px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  gap: 15px;
}
