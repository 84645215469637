@use "./variables" as *;

.modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  width: 0;
  height: 0;
  z-index: 999;
  display: flex;
  // align-items: center;
  justify-content: center;
  padding: 1rem;

  &__dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: calc(100dvh - 32px);
    overflow: auto;
    position: relative;
    z-index: 999;
  }

  &__content {
    width: 100%;
    max-width: 400px;
    margin: auto;
    padding: 1.25rem;
    border-radius: 10px;
    border: 1px solid $borderLight;
    background: $white;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.06);
    position: relative;
    z-index: 999;
    transition: all 0.3s ease;

    &--lg {
      max-width: 600px;

      @media screen and (min-width: 992px) {
        max-width: 910px;
      }
    }
    &--lg780 {
      max-width: 600px;

      @media screen and (min-width: 992px) {
        max-width: 780px;
      }
    }
    &--lg815 {
      max-width: 600px;

      @media screen and (min-width: 992px) {
        max-width: 815px;
      }
    }
    &--xxxl {
      max-width: 600px;

      @media screen and (min-width: 992px) {
        max-width: 960px;
      }
    }
    &--md {
      max-width: 576px;

      @media screen and (min-width: 1600px) {
        max-width: 576px;
      }
    }
  }
  &__content,
  &__header,
  &__body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    @media screen and (min-width: 1600px) {
      gap: 20px;
    }
  }

  &__header {
    gap: 20px;
    position: relative;

    &--title {
      color: $primaryBlack;
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 30px;
      text-align: left;
      width: 100%;
    }

    .btnClose {
      position: absolute;
      border: none;
      right: -12px;
      top: -12px;
      padding: 0;
    }

    .videoClose {
      position: absolute;
      right: -40px;
      top: -40px;
    }
  }

  &__body {
    &--title {
      color: $primaryBlack;
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 30px;

      &.title--error {
        color: $danger;
      }
    }

    &--message {
      color: $primaryGray;
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 28px;

      &.text--dark {
        font-size: 1rem;
        font-weight: 500;
        line-height: 24px;
        padding-bottom: 1rem;
        margin-bottom: 16px;
        border-bottom: 1px solid $borderLight;
      }
    }
  }

  &__footer {
    width: 100%;

    .btn__group {
      width: 100%;
      justify-content: flex-start;
    }
  }

  &__backdrop {
    position: fixed;
    z-index: 990;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(17, 25, 39, 0.5);
    backdrop-filter: blur(5px);
    display: none;
  }

  &.modal--open {
    opacity: 1;
    visibility: visible;
    width: 100vw;
    height: 100dvh;
    overflow: auto;

    .modal__dialog {
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
      transition: all 0.3s ease;
    }

    .modal__backdrop {
      display: block;
    }
  }
}

.modal {
  &.modalSuccessfull {
    .modal__dialog {
      .modal__content {
        gap: 0;
        max-width: 560px;
        padding: 40px 20px;
        min-height: 300px;
        @media (min-width: 639px) {
          padding: 50px;
        }
        .modal__header--title {
          text-align: center;
          color: $primaryBlack;
          font-size: 1.5rem;
          font-weight: 600;
          line-height: 24px;
          margin: 0 0 6px 0;
        }
        .modal__header {
          &--logo {
            margin-bottom: 1.5rem;
          }
        }
      }
      .modal__body {
        .promptMessage {
          color: $primaryBlack;
          font-size: 1.5rem;
          font-weight: 600;
          line-height: 24px;
          text-align: center;
          text-transform: capitalize;
        }
        .branding {
          &__logo {
            margin-bottom: 1.875rem;
            text-align: center;
            cursor: pointer;
            @media (max-width: 639px) {
              margin-bottom: 0.625rem;
            }
            &__img {
              display: block;
              margin: 0 auto;
              @media (max-width: 639px) {
                max-width: 215px;
              }
            }
          }
        }
        .form {
          flex-grow: 1;
          width: 100%;
          .messageSuccess,
          .messageError {
            margin-bottom: 1.125rem;
          }
        }
      }

      .modal__footer {
        margin-top: 2rem;

        .btn__group {
          justify-content: center;
        }
      }
    }
  }
}
.modal {
  &.modalTrash {
    .modal__dialog {
      .modal__content {
        max-width: 400px;
        .modal__header {
          align-items: flex-start;
          gap: 16px;
        }
        .modal__header--title {
          display: flex;
          align-items: center;
          font-size: 1.125rem;
          gap: 8px;
          &--red {
            color: $danger;
          }
        }
        .para {
          font-size: 1rem;
          font-weight: 400;
          color: $primaryBlack;
          margin-bottom: 0;
        }
      }
    }
  }
}
.modal {
  &.modalForm {
    .modal__dialog {
      .modal__content {
        max-width: 576px;
      }

      &.sm {
        .modal__content {
          max-width: 420px;
        }
      }
    }
  }
}
.modal {
  &--hazmatDetail {
    .modal__dialog {
      padding: 0;
      .modal__content {
        gap: 0;
        padding: 0;
        .modal__header {
          padding: 1.25rem 4rem 1.25rem 1.25rem;
          border-bottom: 1px solid $lightgray;
          @media screen and (min-width: 1600px) {
            padding: 1.25rem 4rem 1.25rem 1.25rem;
          }
          &--title {
            font-size: 1.5rem;
            font-weight: 600;
          }
          .btnClose {
            top: 26px;
            right: 25px;
          }
        }
        .modal__body {
          gap: 0;
          padding: 1.25rem;
          @media screen and (min-width: 1600px) {
            padding: 1.5rem;
          }

          .formRow {
            width: 100%;
            &:last-child {
              margin-bottom: 0;
            }
          }
          .orderPriceDetial {
            width: 100%;
            .title {
              margin-bottom: 1rem;
            }
            &__history {
              display: flex;
              flex-direction: column;
              width: 100%;
              height: 100%;
              border: 1px solid $lightgray;
              border-radius: 6px;
              &__header {
                padding: 1rem;
                display: flex;
                align-items: center;
                background: #EBEEEF;
                justify-content: space-between;
                gap: 10px;
                .item {
                  font-size: 0.875rem;
                  font-weight: 700;
                  color: $darkGray;
                }
              }
              &__body {
                display: flex;
                align-items: center;
                flex-direction: column;
                gap: 12px;
                padding: 1rem;
                height: 100%;
                .item {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  gap: 10px;
                  font-size: 0.875rem;
                  font-weight: 500;
                  color: $darkGray;
                }
              }
              &__footer {
                padding: 1rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-top: 1px solid $lightgray;
                gap: 10px;
                .item {
                  font-size: 0.875rem;
                  font-weight: 700;
                  color: $darkGray;
                }
              }
            }
          }
          .currentUser {
            font-size: 1rem;
            font-weight: 600;
            color: $primaryBlack;
            span {
              display: inline-block;
              vertical-align: middle;
              font-weight: 400;
            }
          }
          .userInvitationInfo {
            background: rgba($primary, 8%);
            padding: 0.75rem 1rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 15px;
            width: 100%;
            border-radius: 12px;
            .profile {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 10px;
              cursor: pointer;

              &__image {
                width: 40px;
                height: 40px;
                flex: 0 0 40px;
                border-radius: 50%;
                overflow: hidden;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 50%;
                }
              }

              &__initials {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                flex: 0 0 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $white;
                color: $primary;
                border: 1px solid $primary;
                font-size: 1.125rem;
                font-weight: 600;
                line-height: 20px;
                transition: all 0.3s ease;
                overflow: hidden;
              }
              &__detail {
                display: flex;
                flex-direction: column;
                gap: 2px;
                width: calc(100% - 40px);
                font-size: 0.75rem;
                font-weight: 400;
                color: rgba($primaryBlack, 70%);
              }
              &__name {
                font-size: 1rem;
                font-weight: 500;
                color: $primary;
                line-height: 16px;
                text-transform: capitalize;
              }
            }
          }
          .accordionList {
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 100%;
            max-height: 500px;
            overflow-y: auto;
            padding: 0.5rem 0.5rem 0.5rem 0;
            &__item {
              display: flex;
              flex-direction: column;
              gap: 20px;
              width: 100%;
              padding: 1.875rem 1.5rem;
              background: $white;
              box-shadow: 0px 1px 9.8px 0px #080f341c;
              border-left: 8px solid $primary;
              border-radius: 8px;
              .accordionHeading {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 10px;
                font-size: 1.25rem;
                font-weight: 500;
                color: $darkGray;
                min-height: 25px;
                margin-bottom: 0;
                position: relative;
                cursor: pointer;
                .title {
                  display: flex;
                  align-items: center;
                  gap: 10px;
                  .icon {
                    display: flex;
                    align-items: center;
                  }
                  span {
                    font-weight: 400;
                    color: $gray;
                  }
                }
                .iconArrow {
                  transform: rotate(0deg);
                  transition: transform 1s linear 0s;
                }
              }
              .accordionData {
                display: none;
                .orderPaymentHistory {
                  padding: 1.5rem;
                  border: 1px solid $lightgray;
                  border-radius: 6px;
                  &__row {
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: space-between;
                    align-items: flex-start;
                    gap: 16px;
                    flex-wrap: wrap;
                  }
                  &__col {
                    display: flex;
                    flex-direction: column;
                    gap: 12px;
                    width: 48.5%;
                  }
                  &__item {
                    display: flex;
                    align-items: flex-start;
                    gap: 8px;
                    font-size: 1rem;
                    font-weight: 600;
                    color: $darkGray;
                    .text {
                      width: 160px;
                    }
                    .value {
                      font-weight: 500;
                      line-break: anywhere;
                      &--primary {
                        color: $primary;
                      }
                      &--success {
                        color: $success;
                      }
                      &--semibold {
                        font-weight: 600;
                      }
                    }
                  }
                }
              }
              &.active {
                .accordionHeading {
                  .iconArrow {
                    transform: rotate(180deg);
                    transition: transform 1s linear 0s;
                  }
                }
                .accordionData {
                  display: block;
                }
              }
            }
          }
        }
        .modal__footer {
          padding: 0 1.25rem 1.25rem;
          @media screen and (min-width: 1600px) {
            padding: 0 1.5rem 1.5rem;
          }
        }
      }
    }
  }
}
.modal {
  &--orderCharge {
    .modal__dialog {
      padding: 0;
      .modal__content {
        gap: 0;
        padding: 0;
        max-width: 1600px;
        .modal__header {
          padding: 1.25rem 4rem 1.25rem 1.25rem;
          border-bottom: 1px solid $lightgray;
          @media screen and (min-width: 1600px) {
            padding: 1.25rem 4rem 1.25rem 1.25rem;
          }
          &--title {
            font-size: 1.5rem;
            font-weight: 600;
          }
          .btnClose {
            top: 26px;
            right: 25px;
          }
        }
        .modal__body {
          gap: 0;
          padding: 1.25rem;
          @media screen and (min-width: 1600px) {
            padding: 1.5rem;
          }
          .formRow {
            width: 100%;
            &:last-child {
              margin-bottom: 0;
            }
          }
          .form__section__title {
            font-size: 1.25rem;
            font-weight: 600;
            color: $primaryBlack;
            line-height: 24px;
            padding: 1rem 1.25rem;
            margin-bottom: 0;
          }
          .tableWrapCard {
            background: $white;
            width: 100%;
            box-shadow: 0px 0px 12px 0px #0000001f;
            border-bottom: 1px solid $borderLight;
            &__innerData {
              padding: 1.25rem;
              border-top: 1px solid $borderLight;
              .form__section__title {
                padding: 0;
                margin-bottom: 1rem;
              }
            }
            .tableWrap {
              padding: 0;
              .tableContainer {
                height: auto;
                .table {
                  th,
                  td {
                    .sortingDiv {
                      &--center {
                        justify-content: center;
                      }
                    }
                    .cellText {
                      position: relative;
                      &__subText {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 0.625rem;
                        font-weight: 400;
                        position: absolute;
                        left: -5px;
                        width: 100%;
                        bottom: -18px;
                      }
                      &--center {
                        text-align: center;
                      }
                    }
                  }
                }
              }
              .table {
                thead {
                  tr {
                    background: #ebeeef;
                  }
                }
                tbody {
                  tr {
                    border-bottom: none;
                    background: #6b6b6b0a;
                    &:nth-child(even) {
                      background: #fff;
                    }
                  }
                }
              }
            }
            .pagination {
              min-height: 1px;
              position: static;
              bottom: auto;
            }
          }
          .tabsWrap {
            padding-top: 0;
            width: 100%;
          }
          .tabsContent {
            width: 100%;
            .details {
              padding: 0;
              .form__section__title{
                padding: 0 0 1rem 0;
                
              }
            }
            
          }
        }
        .modal__footer {
          padding: 0 1.25rem 1.25rem;
          @media screen and (min-width: 1600px) {
            padding: 0 1.5rem 1.5rem;
          }
        }
      }
    }
  }
}

.modal-body-left {
  align-items: flex-start;
}
