* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box; 
}
h1, h2, h3, h4, h5, h6, p {
  margin-top: 0;
  margin-bottom: 20px; 
}

h1, h2, h3, h4, h5 {
  font-weight: 500; 
}
p {
  line-height: 1.6; 
}
ul {
  list-style: none;
  padding-left: 0;
}
a {
  color: #000000;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; 
}
img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
}
a:hover {
  text-decoration: none;
  color: #54536e; 
}
body {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  line-height: 1.333; 
}
.siteBanner {
  display: flex;
  flex-direction: column;
  gap: 32px;
  background: url(../../assets/svg/banner-img.svg) no-repeat;
  background-position: center center;
  background-size: cover;
  padding:20px;
  background-color: #26617D;
}
.siteHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.siteHeader .heading {
  font-size: 36px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 0;
}
.siteHeader .logo {
  max-width: 170px;
}
.siteHeader .logo img {
  max-height: 60px;
}
.siteBannerContent {
  display: flex;
  flex-direction: column;
  max-width: 1160px;
  margin: 0 auto;
  gap: 48px;
  color: #ffffff;
}
.siteBannerContent .topblock h2 {
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  position: relative;
  padding-bottom: 1rem;
}
.siteBannerContent .topblock h2::after {
  content: '';
  background: linear-gradient(90.03deg, rgba(255, 255, 255, 0) 0.03%, #FFFFFF 26.8%, #FFFFFF 62.82%, rgba(255, 255, 255, 0) 93.48%);
  position: absolute;
  width: 600px;
  height: 6px;
  bottom: -6px;
  left: 15px;
  right: 15px;
  margin: 0 auto;
  z-index: 1;
}
.siteBannerContent .topblock p {
  font-size: 24px;
  text-align: center;
  margin-bottom: 0;
}
.siteBannerContent .commonList {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}
.siteBannerContent .commonList .item {
  width: calc(100% / 3);
  padding:0 15px 20px;
}
.siteBannerContent .commonList .card {
  max-width: 260px;
  margin: 0 auto;
  text-align: center;
}
.siteBannerContent .commonList .card .icon {
  min-height: 65px;
  margin-bottom: 20px;
}
.siteBannerContent .commonList .card h3 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}
.siteBannerContent .commonList .card p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}
.commonSec {
  background: #ffffff;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.commonCardHead {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 16px;
}
.commonCardHead h4 {
  font-size: 30px;
  font-weight: 600;
  color: #344054;
  margin-bottom: 15px;
}
.commonCardHead p {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  font-size: 16px;
  font-weight: 600;
  color: #D0D5DD;
  margin-bottom: 0;
}
.commonCardHead p .date {
  color:#1D2939;
}
.commonCardHead p .date span {
  font-size: 14px;
  font-weight: 400;
  color: #667085;
}
.commonCardHead p .text {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #12B76A;
}
.commonCardHead .btnGroup {
  display: flex;
  align-items: center;
  gap: 10px;
}
.commonCardHead .btnGroup .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 14px;
  background: #ffffff;
  font-size: 14px;
  font-weight: 400;
  color: #667085;
  line-height: 24px;
  width: -moz-fit-content;
  width: fit-content;
  white-space: nowrap;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
  min-height: 44px;
  gap: 8px;
  transition: all 0.3s ease;
  cursor: pointer;
}
.commonCardHead .btnGroup .btn.btnBlue {
  background: #26617D;
  border-color: #26617D;
  color: #ffffff;
}
.commonCardHead .btnGroup .btn:hover {
  opacity: .7;
}
.commonCard {
  background: #ffffff;
  padding: 24px 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-shadow: 0px 0px 13.7px 0px #5D5D5D40;
  border-radius: 10px;
}
.stepsWrap {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  list-style: none;
}
.stepsWrap__item {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: calc(100% / 4);
  padding: 40px 0;
  position: relative;
  font-size: 1rem;
  font-weight: 500;
  color: #D0D5DD;
  text-align: center;
  transition: all 1s ease;
}
.stepsWrapTwo .stepsWrap__item {
  width: calc(100% / 2);
}
.stepsWrap__item__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:12px;
  width: 170px;
  position: absolute;
  bottom: 0;
}
.stepsWrap__item .circle {
  background: #D0D5DD;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 22px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  position: relative;
  transition: all 1s ease;
}
.stepsWrap__item:before {
  content: "";
  background: #D0D5DD;
  height: 2px;
  width: calc(100% - 22px);
  position: absolute;
  top: 38px;
  left: calc(-50% + 11px);
  transition: all 1s ease;
  z-index: 0;
}
.stepsWrap__item:first-child:before {
  display: none;
}
.stepsWrap__item--done .circle {
  background: #12B76A;
  transition: all 1s ease;
}
.stepsWrap__item--cancel .circle {
  background: #D92D20;
}
.stepsWrap__item--active::before {
  background: #12B76A;
  transition: all 1s ease;
}
.stepsWrap__item--cancel::before {
  background: #D92D20;
}
.stepsWrap__item--done .text {
  color: #12B76A;
} 
.stepsWrap__item--cancel .text{
  color: #D92D20;
}
.stepsWrap__item .value {
  font-size: 14px;
  font-weight: 400;
  color: #667085;
}
.commonCardRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}
.commonCard h4 {
  font-size: 24px;
  font-weight: 600;
  color: #344054;
  margin-bottom: 0;
}
.commonCard h5 {
  font-size: 20px;
  font-weight: 600;
  color: #344054;
  margin-bottom: 0;
}
.commonCard .orderInfo {
  display: flex;
  align-items: center;
  gap: 16px;
}
.commonCard .orderInfo .picture {
  background: #ddd;
  flex: 0 0 110px;
  height: 110px;
  width: 110px;
  border: 1px solid #D0D5DD;
  border-radius: 16px;
  overflow: hidden;
}
.commonCard .orderInfo .picture img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 16px;
}
.commonCard .orderInfo .content h6 {
  font-size: 20px;
  font-weight: 400;
  color: #344054;
  margin-bottom: 5px;
}
.commonCard .orderInfo .content p {
  font-size: 15px;
  font-weight: 400;
  color: #667085;
  margin-bottom: 0;
}
.commonCard .tableWrap  {
  margin: 0 -30px;
  overflow-x: auto;
  padding: 0;
}
.commonCard .tableWrap .table {
  display: table;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
.commonCard .tableWrap .table thead {
  display: table-header-group;
  background: #EBEEEF;
  border-left: 1px solid #EBEEEF;
  border-right: 1px solid #EBEEEF;
}
.commonCard .tableWrap .table thead tr {
  border-top: 1px solid #EBEEEF;
  border-bottom: 0;
}
.commonCard .tableWrap .table tbody {
  display: table-row-group;
}
.commonCard .tableWrap .table tr {
  color: inherit;
  display: table-row;
  vertical-align: middle;
  outline: 0;
  height: 54px;
  border-bottom: 1px solid #B2B2B2;
}
.commonCard .tableWrap .table th,
.commonCard .tableWrap .table td {
  padding:10px 30px;
  font-size: 13px;
  font-weight: 400;
  color: #344054;
  line-height: 20px;
  position: relative;
  text-align: left;
  vertical-align: middle;
}
.commonCard .tableWrap .table th:first-child,
.commonCard .tableWrap .table td:first-child {
  width: 140px;
}
.commonCard .tableWrap .table tbody td {
  border-left: 1px solid #B2B2B2;
}
.commonCard .tableWrap .table tbody {
  border-left: 1px solid #B2B2B2;
  border-right: 1px solid #B2B2B2;
}
.commonCard .tableWrap .table tbody td:first-child,
.commonCard .tableWrap .table tbody td:nth-child(2) {
  border-left: none;
}
.commonCard .tableWrap .table .sortingDiv {
  display: flex;
  gap: 8px;
  align-items: center;
}
.commonCard .tableWrap .table .sortingDiv.center {
  justify-content: center;
}
.commonCard .tableWrap .table th {
  font-size: 14px;
  font-weight: 500;
}
.commonCard .tableWrap .table .center {
  text-align: center;
}
@media screen and (max-width: 1200px) {
  .siteHeader .heading {
    font-size: 26px;
  }
  .siteHeader .logo {
    max-width: 136px;
  }
}
@media screen and (max-width: 639px) {
  .siteHeader .heading {
    font-size: 22px;
  }
  .siteHeader .logo {
    max-width: 124px;
  }
  .siteBannerContent {
    gap: 30px;
  }
  .siteBannerContent .topblock h2 {
    font-size: 24px;
  }
  .siteBannerContent .topblock h2::after {
    width: 300px;
  }
  .siteBannerContent .topblock p {
    font-size: 16px;
  }
  .siteBannerContent .commonList .item {
    width: calc(100% / 1);
    padding-bottom: 24px;
  }
  .siteBannerContent .commonList .card .icon {
    min-height: 1px;
    margin-bottom: 15px;
  }
  .siteBannerContent .commonList .card h3 {
    font-size: 18px;
    margin-bottom: 8px;
  }
  .siteBannerContent .commonList .card p {
    font-size: 14px;
  }
  .commonSec {
    gap: 24px;
  }
  .commonCard {
    padding: 20px 16px;
    gap: 16px;
  }
  .commonCard h4 {
    font-size: 20px;
  }
  .commonCardHead h4 {
    font-size: 22px;
    margin-bottom: 10px;
  }
  .commonCardHead p {
    font-size: 14px;
    gap: 6px;
  }
  .commonCard .orderInfo .picture {
    flex: 0 0 80px;
    height: 80px;
    width: 80px;
  }
  .commonCard .orderInfo .content h6 {
    font-size: 16px;
  }
  .commonCard .orderInfo .content p {
    font-size: 14px;
  }
  .commonCard h5 {
    font-size: 18px;
  }
  .stepsWrap__item {
    padding: 44px 0;
    font-size: 11px;
  }
  .stepsWrap__item .value {
    font-size: 10px;
    max-width: 62px;
  }
  .commonCard .tableWrap {
    margin: 0 -16px;
  }
  .commonCard .tableWrap .table {
    min-width: 715px;
  }
  .commonCard .tableWrap .table th,
  .commonCard .tableWrap .table td {
    padding:10px 16px;
  }
}