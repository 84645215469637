.loader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  display: none;

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    height: 220px;
    border-radius: 50%;
    box-shadow: 0px 10px 18px 0px #00000040;
    overflow: hidden;
    position: relative;
    z-index: 2;
    @media (max-width: 639px) {
      width: 100px;
      height: 100px;
    }
  }

  &__backdrop {
    position: fixed;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(17, 25, 39, 0.5);
    backdrop-filter: blur(5px);
  }

  &--show {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
