// Varaibles
$primary: #26617d;
$blue: #28A0E8;
$primaryBlue: #eaf5fd;
$secondary: #6c737f;
$white: #ffffff;
$black: #000000;
$primaryBlack: #150823;
$primaryGray: #6c6c6c;
$white: #fff;
$gray: #808080;
$lightgray: #d2d6db;
$darkgray: #333333;
$darkGray: #4d5761;
$lightGray: #384250;
$border: #666666;
$danger: #f04438;
$dangerLight: #f56969;
$success: #16cb79;
$borderLight: #e5e7eb;
// Easing
$ease-time: 200ms;
$easing: ease-in-out;
