$xs: 0 !default;
$sm: 639px !default;
$md: 768px !default;
$lg: 992px !default;
$xl: 1200px !default;
$xxl: 1400px !default;
$xxxl: 1600px !default;
$grid-breakpoints: (
  xs: $xs,
  sm: $sm,
  md: $md,
  lg: $lg,
  xl: $xl,
  xxl: $xxl,
);

// Default padding for grid cells
$gutter-x: 1rem !default;

.container {
  max-width: 100%;
  width: 100%;
  padding: 0 $gutter-x;
  margin: 0 auto;
  display: block;

  @media screen and (min-width: $sm) {
    max-width: 540px;
    padding: 0 $gutter-x;
  }
  @media screen and (min-width: $md) {
    max-width: 720px;
  }
  @media screen and (min-width: $lg) {
    max-width: 960px;
  }
  @media screen and (min-width: $xl) {
    max-width: 1140px;
  }
  @media screen and (min-width: $xxl) {
    max-width: 1320px;
  }
  &--fluid {
    width: 100%;
    padding: 0 $gutter-x;
  }
}

div[class^="s_col_"] {
  padding: 0 $gutter-x;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$gutter-x;
  margin-right: -$gutter-x;

  > * {
    flex-shrink: 0;
    width: 100%;
  }
}

.flex {
  display: flex;

  &--inline {
    display: inline-flex;
  }

  &--nowrap {
    flex-wrap: nowrap;
  }

  &--wrap {
    flex-wrap: wrap;
  }

  &--grow {
    flex-grow: 1;
  }

  &--column {
    flex-direction: column;
  }
}

.block {
  display: block;
}

.justifyspace {
  &--between {
    justify-content: space-between;
  }

  &--end {
    justify-content: flex-end;
  }

  &--start {
    justify-content: flex-start;
  }

  &--center {
    justify-content: center;
  }
}

.alignitems {
  &--center {
    align-items: center;
  }

  &--end {
    align-items: flex-end;
  }

  &--start {
    align-items: flex-start;
  }
}

.align {
  &--right {
    text-align: right !important;
  }
  &--center {
    text-align: center !important;
  }
}

// Grid loop
$columns: 12; // Max number of columns

.col_flex {
  width: 100%;

  &--inline {
    width: auto;
  }
}
// Grid cells for small devices
@for $i from 1 through $columns {
  .col_xs_#{$i} {
    flex: 0 0 auto;
    width: calc(100% / $columns * $i);
  }
}

// Grid cells for small-medium devices
@media (min-width: $sm) {
  @for $i from 1 through $columns {
    .col_sm_#{$i} {
      flex: 0 0 auto;
      width: calc(100% / $columns * $i);
    }
    .sm_offset_#{$i} {
      margin-left: calc(100% / $columns * $i);
    }
  }
}

// Grid cells for medium devices
@media (min-width: $md) {
  @for $i from 1 through $columns {
    .col_md_#{$i} {
      flex: 0 0 auto;
      width: calc(100% / $columns * $i);
    }
    .md_offset_#{$i} {
      margin-left: calc(100% / $columns * $i);
    }
  }
}

// Grid cells for large devices
@media (min-width: $lg) {
  @for $i from 1 through $columns {
    .col_lg_#{$i} {
      flex: 0 0 auto;
      width: calc(100% / $columns * $i);
    }
    .lg_offset_#{$i} {
      margin-left: calc(100% / $columns * $i);
    }
  }
}

// Grid cells for extra large devices
@media (min-width: $xl) {
  @for $i from 1 through $columns {
    .col_xl_#{$i} {
      flex: 0 0 auto;
      width: calc(100% / $columns * $i);
    }
    .xl_offset_#{$i} {
      margin-left: calc(100% / $columns * $i);
    }
  }
}

// Grid cells for extra-extra large devices
@media (min-width: $xxl) {
  @for $i from 1 through $columns {
    .row > .col_xxl_#{$i} {
      flex: 0 0 auto;
      width: calc(100% / $columns * $i);
    }
    .xxl_offset_#{$i} {
      margin-left: calc(100% / $columns * $i);
    }
  }
}

.formRow {
  display: grid;
  gap: 12px;
  margin: 0 0 12px 0;
  grid-template-columns: minmax(0, 1fr);

  @media screen and (min-width: $md) {
    gap: 12px;
    margin: 0 0 12px 0;
  }

  @media screen and (min-width: $xl) {
    gap: 16px;
    margin: 0 0 16px 0;
  }

  @media screen and (min-width: $xxxl) {
    gap: 24px;
    margin: 0 0 24px 0;
  }

  &__full {
    grid-template-columns: minmax(0, 1fr);
  }

  &__2 {
    grid-template-columns: repeat(1, minmax(0, 1fr));

    @media screen and (min-width: $md) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  &__3 {
    grid-template-columns: repeat(1, minmax(0, 1fr));

    @media screen and (min-width: $md) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media screen and (min-width: $lg) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

  &__4 {
    grid-template-columns: repeat(1, minmax(0, 1fr));

    @media screen and (min-width: $sm) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media screen and (min-width: $xl) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
  &__5 {
    grid-template-columns: repeat(1, minmax(0, 1fr));

    @media screen and (min-width: $sm) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media screen and (min-width: $xl) {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }
  }

  &__sm {
    gap: 8px !important;
    .formField__select.MuiFormControl-root
      .MuiInputBase-root
      .MuiSelect-select {
      padding: 0.5rem 0.5rem 0.5rem 0.75rem;
    }
    .formField__select.MuiFormControl-root .MuiInputBase-root svg {
      right: 10px;
    }
    .formField__input.sm {
      padding: 0.5rem;
    }
  }
  &--gapNone {
    gap: 0;
  }
  .formCol {
    width: 100%;

    &.flexField {
      display: flex;
      align-items: flex-end;
      gap: 8px;
    }
    .formRow {
      margin-bottom: 0;
      @media screen and (min-width: $md) {
        gap: 12px;
      }

      @media screen and (min-width: $xl) {
        gap: 12px;
      }

      @media screen and (min-width: $xxxl) {
        gap: 24px;
      }
    }
    &--flexRow {
      display: flex;
      align-items: center;
      gap: 16px;
      .form__select {
        font-weight: 500;
      }
    }
  }

  &.formRow--responsive {
    @media screen and (min-width: $xl) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
}
