.title {
  font-weight: 600;

  &--h1 {
    font-size: 2rem;
  }

  &--h2 {
    font-size: 1.75rem;
  }

  &--h3 {
    font-size: 1.5rem;
  }

  &--h4 {
    font-size: 1.125rem;
  }

  &--h5 {
    font-size: 1.25rem;
  }

  &--h6 {
    font-size: 0.875rem;
  }
}
