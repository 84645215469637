@use "./variables" as *;
@use "./grid" as *;

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0.875rem;
  gap: 8px;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 24px;
  width: -moz-fit-content;
  width: fit-content;
  transition: all 0.3s ease;
  border-width: 1px;
  border-style: solid;
  white-space: nowrap;

  @media screen and (max-width: $sm) {
    padding: 0.5rem 0.675rem;
  }

  &:hover {
    opacity: 0.8;
    transition: all 0.3s ease;
  }

  svg {
    path {
      transition: all 0.3s ease;
    }
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &--lg {
    padding: 0.625rem 2.5rem;
  }

  &__primary {
    background-color: $primary;
    color: $white;
    border-color: $primary;

    &.btn__outlined {
      border-color: $primary;
      background-color: $white;
      color: $primary;

      &:hover {
        background-color: $primary !important;
        color: $white;

        svg {
          path {
            stroke: $white;
            transition: all 0.3s ease;
          }
        }
      }
    }
  }

  &__primaryBlue {
    background-color: $blue;
    color: $white;
    border-color: $blue;
 
    &.btn__outlined {
      border-color: $blue;
      background-color: $white;
      color: $blue;
 
      &:hover {
        background-color: $blue !important;
        color: $white;
 
        svg {
          path {
            stroke: $white;
            transition: all 0.3s ease;
          }
        }
      }
    }
  }

  &__white {
    background-color: $white;
    color: $primaryBlack;
    border-color: $primaryBlack;

    &:hover {
      border-color: $primary;
      background-color: $primary;
      color: $white !important;

      svg {
        path {
          stroke: $white;
          transition: all 0.3s ease;
        }
      }
    }
  }

  &__danger {
    background-color: $danger;
    border-color: $danger;
    color: $white;

    &:hover {
      border-color: $danger;
      background-color: $danger;
      color: $white;

      svg {
        path {
          stroke: $white;
          transition: all 0.3s ease;
        }
      }
    }

    &.btn__outlined {
      background-color: $white;
      border-color: $danger;
      color: $danger;

      &:hover {
        border-color: $danger;
        background-color: $danger;
        color: $white;

        svg {
          path {
            stroke: $white;
            transition: all 0.3s ease;
          }
        }
      }
    }
  }
  &__success {
    background-color: $success;
    border-color: $success;
    color: $white;

    &:hover {
      border-color: $success;
      background-color: $success;
      color: $white;

      svg {
        path {
          stroke: $white;
          transition: all 0.3s ease;
        }
      }
    }

    &.btn__filled {
      background-color: $success;
      border-color: $success;
      color: $white;

      &:hover {
        border-color: $success;
        background-color: $white;
        color: $success;

        svg {
          path {
            stroke: $success;
            transition: all 0.3s ease;
          }
        }
      }
    }
  }

  &__group {
    display: flex;
    align-items: center;
    gap: 16px;
    &--end {
      justify-content: flex-end;
    }
    .addMore {
      font-size: 0.875rem;
      font-weight: 500;
      color: $primary;
      &__disable {
        opacity: 0.5;
        pointer-events: none;
      }
    }
    @media screen and (max-width: $sm) {
      .btn {
        width: 100%;
      }
    }
  }

  &--sm {
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
    padding: 0;
  }
  &--medium {
    font-weight: 500;
  }
  &__text {
    background-color: transparent;
    color: $primaryBlack;
    border: 0;
    text-decoration: underline;
    &:hover {
      color: $primary;
    }
  }
}
.backBtn {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-size: 1rem;
  font-weight: 500;
  color: $primary;
  &:hover {
    opacity: 0.8;
    transition: all 0.3s ease;
  }
}
.link {
  text-decoration: underline;
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 24px;
  transition: all 0.3s ease;
  cursor: pointer;
  &--bold {
    font-weight: 600;
  }

  &__primary {
    color: $primary;
  }

  &__dark {
    color: $primaryBlack;
  }

  &__danger {
    color: $danger;
  }

  &:hover {
    opacity: 0.8;
    transition: all 0.3s ease;
  }
}
