@use "../abstracts/variables" as *;

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.25rem;
  border-top: 1px solid $borderLight;
  min-height: 60px;
  background-color: $white;
  position: sticky;
  bottom: 0;

  @media screen and (max-width: 639px) {
    justify-content: center;
  }

  &__center {
    justify-content: center;
  }

  &__result {
    color: $primaryBlack;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 20px;

    @media screen and (max-width: 639px) {
      display: none;
    }
  }

  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;

    &--item {
      width: 26px;
      height: 26px;
      padding: 0.25rem 0.625rem;
      border-radius: 4px;
      background-color: $white;
      color: $primaryBlack;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.page-active {
        background-color: $primary;
        color: $white;
        font-size: 0.875rem;
        font-weight: 700;
        line-height: 20px;
      }
    }
  }

  &__flex {
    display: flex;
    justify-content: center;
    align-items: center;
    .btn__group {
      gap: 2px;
    }
  }

  &__button {
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    h6 {
      color: $primaryBlack;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 20px;
    }
    .formField__input {
      width: 26px;
      height: 26px;
      padding: 0.25rem;
      text-align: center;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 20px;
      border: 1px solid $lightgray;
      border-radius: 4px;
    }
    .btn__primary {
      padding: 0.25rem 0.625rem;
      height: 26px;
      font-weight: 600;
    }

    @media screen and (max-width: 639px) {
      display: none;
    }
  }

  .MuiPagination-root {
    .MuiPagination-ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 2px;
      li {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .MuiPaginationItem-root {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 26px;
        height: 26px;
        background-color: $white;
        border: 0;
        border-radius: 4px;
        color: $primaryBlack;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 20px;
        margin: 0;

        &.Mui-selected {
          background-color: $primary;
          color: $white;
        }
      }
    }
  }
}
